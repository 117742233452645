<template>
  <div class="mian-container">
    <div>
      <div class="header w">
      <!-- <div class="logo">
        <img
          th:src="@{/api/layui/img/logo.jpg}"
          alt=""
        >
        <div class="name">
          龙之宝电子保函支付平台
        </div>
      </div> -->
      </div>
      <div class="payinfo-title">
        <div v-if="isShow" class="payinfo-title-content">
          银行转账(关联流水)
        </div>
        <div v-else class="payinfo-title-content">
          银行转账(随机账号)
        </div>
        <div class="payinfo-title-money">
          支付金额(元)：<span>{{ throundsHandle(userAcctInfo.sumPremium) }}</span>
        </div>
      </div>
      <div class="payinfo-content">
        <div class="payinfo-content-bank">
          <div style="margin-bottom: 30px;">
            <p>该订单要求用企业基本户支付，请用企业基本户转出；</p>
            <!-- <p>预留基本户账号：<strong class="basic-text"><span>{{ userAcctInfo.basicAccountNo }}</span></strong></p> -->
          </div>
          <div class="pay-remarks">
            转账银行信息 <span><button
              class="copy"
              @click="copy"
            >复制收款信息</button></span>
            <input
              class="input"
              type="text"
              name=""
              id=""
              style="position: absolute; opacity: 0;"
            >
          </div>
          <div class="copy-close">
            <span>复制成功</span>
          </div>
          <table class="bank-content">
            <tr class="bank-content-hint">
              <td />
              <td>
              <!-- 请务必注意收款账号，每笔申请账号都会变化！ -->
              <!-- <button>
                <a style="font-size: 12px;color: black;text-decoration:none;">账户不一致说明附件</a>
              </button> -->
              </td>
            </tr>
            <tr class="bank-content-title">
              <td class="bankMsg">
                业务订单号：
              </td>
              <td class="bankMsg">
                {{ userAcctInfo.orderNo }}
              </td>
            </tr>
            <tr>
              <td class="bankMsg">
                收款人户名：
              </td>
              <td class="bankMsg">
                {{ accountInfo.receiveAcctName }}
              </td>
            </tr>
            <tr>
              <td class="bankMsg">
                收款账号：
              </td>
              <td class="bankMsg">
                {{ accountInfo.receiveAcct }}
              </td>
            </tr>
            <tr>
              <td class="bankMsg">
                开户银行：
              </td>
              <td class="bankMsg">
                {{ accountInfo.receiveBank }}
              </td>
            </tr>
            <tr>
              <td class="bankMsg">
                联行号：
              </td>
              <td class="bankMsg">
                {{ accountInfo.receiveBankCode }}
              </td>
            </tr>
            <tr>
              <td class="bankMsg">
                支付金额：
              </td>
              <td class="bankMsg red">
                {{ throundsHandle(userAcctInfo.sumPremium) }}
              </td>
            </tr>
          </table>
        </div>
        <div class="payinfo-content-tips">
          <h4 class="color-red">
            <strong>注意事项</strong>（必读）
          </h4>
          <ol>
            <li>如遇收款账号过长无法输入或未查到汇款流水的情况，请及时与我司客服联系。</li>
            <div v-if="isShow">
              <li>
                线下汇款后，<a
                  href="javascript:;"
                  @click="reload"
                >[点此刷新]</a> 汇款记录，并勾选汇款流水，才能完成支付，请在投标结束前完成。
              </li>
              <li>只能选择与支付金额相同的汇款流水才能支付成功。</li>
              <li>汇款金额如与支付金额不一致，可用于支付其他金额匹配的订单。</li>
              <li>因银行到账时间会有所延迟，一般在5至30分钟内可以到账，若长时间未查到汇款流水，请及时与我司客服联系。</li>
            </div>

          <!-- <li>
            <span class="color-red">收款账号与订单是关联的</span>，请留意收款账号变化；
          </li>
          <li>
            转账金额与<span class="color-red">订单金额务必保持一致</span>，请勿多转账、少转账和分次转账；
          </li>
          <li>如遇收款账号过长无法输入的情况，请及时与我司客服联系（电话：0563-2732778）。</li>
          <li>收款账号每笔随机变化，请在投标结束前完成，无需汇款备注。</li>
          <li>付款成功后，预计十分钟左右可刷新查看订单。</li> -->
          </ol>
        </div>
      </div>
    </div>
    <div
      class="submit"
      v-if="isShow"
    >
      <a-table
        :data-source="payLogList"
        :columns="columns"
        :scroll="{ y: 500 }"
      >
        <span
          slot="num"
          slot-scope="space, text, index"
        >
          <a-checkbox v-model="text.checked">{{ index + 1 }}</a-checkbox>
        </span>
        <!-- <span
          slot="vltdat"
          slot-scope="space, text"
        >
          {{ dateFormat(text.vltdat, text.etytim) }}
        </span> -->
      </a-table>

      <a-button
        type="primary"
        @click="submit"
      >
        提交
      </a-button>
    </div>
  </div>
</template>

<script>
import { payList, noOrderList, pay } from '@/api/pay';
import { notification } from 'ant-design-vue';
export default {
  data() {
    return {
      isShow: false, // 表格是否展示
      checked: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'num',
          key: 'num',
          width: '80px',
          scopedSlots: { customRender: 'num' },
        },
        {
          title: '流水号',
          dataIndex: 'refnbr',
          key: 'refnbr',
        },
        {
          title: '付款方名称',
          dataIndex: 'rpynam',
          key: 'rpynam',
        },
        {
          title: '打款金额',
          dataIndex: 'trsamtc',
          key: 'trsamtc',
          width: '100px',
        },
        {
          title: '交易时间',
          dataIndex: 'vltdat',
          key: 'vltdat',
          width: '100px',
          scopedSlots: { customRender: 'vltdat' },
        },
      ],
      accountInfo: {
        receiveAcctName: '',
      },
      userAcctInfo: {},
      payLogList: [],
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    throundsHandle (value) {
      if (value) {
        let a = value.toString().split('.')[0]
        let b = value.toString().split('.')[1]
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ( b ? '.' + b : '')
      } else {
        return value
      }
    },
    refresh() {
      const encrypted = this.$route.query.encrypted;
      const type = this.$route.query.type;
      // type为noorder不展示table
      if (type === 'noorder') {
        this.isShow = false;
        noOrderList(encrypted).then(res => {
          console.log(res);
          this.accountInfo = res.data.accountInfo;
          this.userAcctInfo = res.data.userAcctInfo;
          this.payLogList = res.data.payLogList;
        });
      } else {
        this.isShow = true;
        payList(encrypted).then(res => {
          console.log(res);
          this.accountInfo = res.data.accountInfo;
          this.userAcctInfo = res.data.userAcctInfo;
          this.payLogList = res.data.payLogList;
        });
      }
    },
    reload() {
      location.reload();
    },
    submit() {
      const encrypted = this.$route.query.encrypted;
      let ids = [];
      // eslint-disable-next-line array-callback-return
      this.payLogList.map(item => {
        if (item.checked) {
          ids.push(item.id);
        }
      });
      ids = ids.join(',');
      pay(encrypted, { ids }).then(res => {
        console.log(res);
        if (res.data.status === 200) {
          this.$router.push({ path: `/insure?orderNo=${this.userAcctInfo.orderNo}` });
        } else {
          notification.error({
            message: res.data.message,
          });
        }
      });
    },
    dateFormat(date, time) {
      return date.slice(0, 4) + '/' + date.slice(4, 6) + '/' + date.slice(6, 8) + ' ' + time.slice(0, 2) + ':' + time.slice(2, 4) + ':' + time.slice(4, 6);
    },
    copy() {
      let valid = true;
      const td = document.getElementsByClassName('bankMsg');
      const input = document.getElementsByClassName('input')[0];
      let text = '';
      for (let i = 0; i < td.length; i++) {
        if (i % 2 === 0) {
          text = text + td[i].innerHTML;
        } else {
          text = text + td[i].innerHTML + ';';
        }
      }
      input.value = text;
      input.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      // 复制成功显示
      if (valid) {
        valid = false;
        const copy = document.getElementsByClassName('copy-close')[0];
        copy.classList.add('copy-success');
        setTimeout(function() {
          copy.classList.remove('copy-success');
          valid = true;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
div, span {
  color: rgba(0, 0, 0, .85);
}
.app {
  min-height: 100%;
  background-color: #fff;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  min-width: 650px;
}

.mian-container {
  background-color: #fff;
  display: flex;
  padding: 15px 10vw;
  margin-bottom: 20px;
  > div {
    flex: 1;
  }
}

.payinfo-title {
  border-bottom: 1px solid #ccc;
  width: 100%;
  position: relative;
  color: #333;
  padding-right: 60px;
  padding-top: 30px;
}

.payinfo-title .payinfo-title-content {
  position: absolute;
  left: 0;
  bottom: -1px;
  font-size: 13px;
  line-height: 36px;
  height: 36px;
  padding: 0 20px;
  background-color: #f2f2f2;
}

.payinfo-title .payinfo-title-money {
  font-size: 16px;
  text-align: right;
  padding: 8px 0;
}

.payinfo-title .payinfo-title-money span {
  color: #e73d30;
  font-size: 22px;
}
.red {
  color: #e73d30;
  font-size: 22px;
}
.payinfo-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 20px 0;
}

.payinfo-content-bank {
  width: 90%;
  position: relative;
}

.payinfo-content .payinfo-content-bank p {
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
}

.payinfo-content .payinfo-content-bank .basic-text {
  color: #666;
  font-size: 18px;
}

.payinfo-content .payinfo-content-bank .pay-remarks {
  font-size: 16px;
  margin-bottom: 20px;
}

.payinfo-content .payinfo-content-bank .pay-remarks .copy {
  display: inline-block;
  font-weight: 400;
  margin-left: 10px;
  cursor: pointer;
  color: #99c;
  padding: 0;
  border: 0;
  background-color: #fff;
}

.payinfo-content .payinfo-content-bank .pay-remarks .copy:hover {
  color: #e39300;
}

.payinfo-content .payinfo-content-bank .bank-content {
  width: 100%;
  border-collapse: collapse;
}

.payinfo-content .payinfo-content-bank .bank-content .bank-content-hint td {
  border: none;
  font-size: 18px;
  color: red;
  font-weight: 700;
}

.payinfo-content .payinfo-content-bank .bank-content .bank-content-title td {
  // background: #f2f2f2;
}

.payinfo-content .payinfo-content-bank .bank-content td {
  background: #fff;
  padding: 12px 10px;
  font-size: 16px;
  // text-align: center;
  // border: 1px solid #bcbcbc;
  // min-width: 60px;
  width: 20px;
}

.payinfo-content .payinfo-content-bank .bank-content td:nth-child(1) {
  text-align: right;
}

.color-red {
  color: red;
  border-top: 1px solid #666;
  padding-top: 20px;
  margin-top: 20px;
}

.payinfo-content>div {
  width: 90%;
}

.payinfo-content .payinfo-content-tips h4 {
  font-size: 16px;
  font-weight: 400;
}

.payinfo-content .payinfo-content-tips ol {
  padding-left: 20px;
  margin: 0;
}

.payinfo-content .payinfo-content-tips ol li {
  list-style: decimal;
  font-size: 16px;
  margin-top: 20px;
}

.copy-success {
  transition: all 0.5s;
  opacity: 1 !important;
}

.copy-close {
  min-width: 80px;
  padding: 10px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, .7);
  position: absolute;
  top: 95px;
  left: 180px;
  border-radius: 4px;
  border: 1px solid #e6ebf5;
  z-index: 2000;
  line-height: 1.4;
  text-align: justify;
  word-break: break-all;
  transition: all .25s;
  opacity: 0;
  span {
    color: #fff !important;
  }
}

.header .name {
  font-size: 24px;
  line-height: 56px;
  color: #666;
}

.logo {
  margin: 20px;
  display: flex;
}

.logo img {
  width: 56px;
  height: 56px;
  margin-right: 20px;
}
/deep/ .ant-pagination {
  display: none;
}
/deep/ .ant-btn {
  margin: 30px 0 0 0;
  width: 120px;
  height: 40px;
  font-size: 16px;
}
.submit {
  text-align: center;
  padding: 0 50px 30px;
  margin-top: 60px;
}
</style>
